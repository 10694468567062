import React from 'react'

export default function HTML(props) {
  return (
    <html lang="en">
      <head>
        <title>404: Page Not Found</title>
        <meta name="description" content="Page Not Found" />
        <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
        <meta name="robots" content="noindex" />
        <meta name="googlebot-news" content="noindex" />
        <meta httpEquiv="cache-control" content="no-cache, no-store, must-revalidate" />
        <meta httpEquiv="expires" content="0" />
        <meta httpEquiv="pragma" content="no-cache" />
        <link
          rel="preload"
          as="font"
          href="https://fonts.googleapis.com/css?family=Roboto:100,100i,300,300i,400,500"
        />

        <style>{`
          body { margin: 0; font-family: Roboto, sans-serif; background-color: #1f2133; }
          a { background: transparent; }
          a:focus { outline: thin dotted; }
          a:active, a:hover { outline: 0; }
          svg:not(:root) { overflow: hidden; }
          @media print {
            * { color: #000 !important; text-shadow: none !important; background: transparent !important; box-shadow: none !important; }
            a, a:visited { text-decoration: underline; }
            a[href]:after { content: ' (' attr(href) ')'; }
          }
          *,
          *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
          a { color: #428bca; text-decoration: none; }
          a:hover, a:focus { color: #2a6496; text-decoration: underline; }
          a:focus { outline: thin dotted #333; outline: 5px auto -webkit-focus-ring-color; outline-offset: -2px; }
          .x-download__note-header { background: #181928; height: 60px; -webkit-animation: note-header 1s 0.8s ease-in-out forwards; animation: note-header 1s 0.8s ease-in-out forwards; }
          .x-download__note-header-content { padding: 0 32px; max-width: 1170px; margin: 0 auto; position: relative; }
          .x-download__note-header-content .logo-container { position: absolute; right: 32px; top: 14px; width: 40px; overflow: hidden; }
          .x-download__note-header a { font-size: 13px; font-weight: 300; line-height: 1.54; color: #ffffff; line-height: 60px; opacity: 0.6; text-decoration: none; display: flex; align-items: center; }
          .x-download__note-header a svg { margin-right: 10px; }
          .x-download__note-header a:hover,
          .x-download__note-header a:focus,
          .x-download__note-header a:active { opacity: 1; -webkit-transition: opacity 200ms linear; -ms-transition: opacity 200ms linear; transition: opacity 200ms linear; }
          svg:not(:root) { overflow: hidden; }
          button { margin: 0; font-family: inherit; font-size: 100%; }
          button { line-height: normal; }
          button { text-transform: none; }
          button { cursor: pointer; -webkit-appearance: button; }
          button::-moz-focus-inner { padding: 0; border: 0; }
          @media print {
            * { color: #000 !important; text-shadow: none !important; background: transparent !important; box-shadow: none !important; }
          }
          *, *:before, *:after { -webkit-box-sizing: border-box; -moz-box-sizing: border-box; box-sizing: border-box; }
          button { font-family: inherit; font-size: inherit; line-height: inherit; }
          *, ::after, ::before { box-sizing: border-box; }
          h1 { margin-top: 0; margin-bottom: 0.5rem; }
          p { margin-top: 0; margin-bottom: 1rem; }
          a { color: #007bff; text-decoration: none; background-color: transparent; -webkit-text-decoration-skip: objects; }
          a:hover { text-decoration: none; }
          .x__width { max-width: 1170px; margin: 0 auto; box-sizing: content-box; }
          .x__width { padding: 0 16px; }
          .x__button { display: block; background-image: linear-gradient(281deg, #420be6, #a16bb3); color: #fff; position: relative; opacity: 0.8; -webkit-transition: opacity 0.2s linear; transition: opacity 0.2s linear; width: 240px; height: 48px; border-radius: 24px; font-size: 12px; text-transform: uppercase; font-family: Roboto, sans-serif; font-weight: 400; border: 1px solid transparent; outline: none; padding: 2px; background-clip: padding-box !important; box-shadow: 0 10px 30px 0 rgba(111, 57, 205, 0.3); text-decoration: none; background-size: 101%; }
          .x__button:focus, .x__button:hover { opacity: 1; color: #fff; text-decoration: none; }
          .x__button .x__button-align { display: block; height: 100%; line-height: 42px; cursor: pointer; border-radius: 24px; }
          .x__button .x__button-align-text { display: flex; align-items: center; justify-content: center; }
          h1 { font-family: Roboto, sans-serif; }
          .x-page-notfound .x__button { display: inline-block; background-image: linear-gradient(281deg, #420be6, #a16bb3) !important; color: #fff; position: relative; opacity: 0.8; -webkit-transition: opacity 0.2s linear; transition: opacity 0.2s linear; width: 240px; height: 48px; border-radius: 22px; font-size: 12px; text-transform: uppercase; font-family: Roboto, sans-serif; font-weight: 400; border: 1px solid transparent; outline: none; padding: 2px; background-clip: padding-box !important; box-shadow: 0 10px 30px 0 rgba(111, 57, 205, 0.3); text-decoration: none; background-size: 101%; }
          .x-page-notfound .x__button:focus, .x-page-notfound .x__button:hover { opacity: 1; color: #fff; text-decoration: none; }
          .x-page-notfound .x__button .x__button-align { display: block; height: 100%; line-height: 42px; cursor: pointer; border-radius: 24px; }
          .x-page-notfound .x__button .x__button-align-text { display: flex; align-items: center; justify-content: center; }
          .x-page-notfound .x__button--secondary { background: transparent; background-image: none !important; box-shadow: none; border: 1px solid hsla(0, 0%, 100%, 0.2); }
          .x-page-notfound .x__width { max-width: 1170px; margin: 0 auto; box-sizing: content-box; }
          .x-page-notfound .x__width { padding: 0 16px; }
          .x-page-notfound .x-notfound { max-width: 1070px; min-height: calc(100vh - 170px); margin: 76px auto; display: flex; position: relative; z-index: 2; flex-direction: column; justify-content: center; text-align: center; }
          .x-page-notfound .x-notfound__subheading { font-size: 300px; font-weight: 300; color: hsla(0, 0%, 100%, 0.1); display: inline-block; height: 216px; line-height: 216px; -webkit-animation: pageload 0.6s ease forwards; animation: pageload 0.6s ease forwards; opacity: 0; }
          @media (max-width: 550px) {
            .x-page-notfound .x-notfound__subheading { font-size: 175px; }
          }
          .x-page-notfound .x-notfound__heading { font-size: 80px; font-weight: 300; color: #fff; margin-top: -68px; opacity: 0; -webkit-animation: pageload 0.6s ease 0.2s forwards; animation: pageload 0.6s ease 0.2s forwards; }
          @media (max-width: 550px) {
            .x-page-notfound .x-notfound__heading { text-align: center; font-size: 48px; margin: -60px 0 0 -4px; }
          }
          .x-page-notfound .x-notfound__txt { font-weight: 300; font-size: 18px; line-height: 1.78; text-align: center; color: hsla(0, 0%, 100%, 0.4); margin: 8px 0 50px; opacity: 0; -webkit-animation: pageload 0.6s ease 0.2s forwards; animation: pageload 0.6s ease 0.2s forwards; }
          .x-page-notfound .x-notfound__buttons { display: flex; align-items: center; justify-content: center; opacity: 0; -webkit-animation: pageload 0.6s ease 0.4s forwards; animation: pageload 0.6s ease 0.4s forwards; }
          .x-page-notfound .x-notfound__buttons a:first-child { margin-right: 20px; }
          @media (max-width: 550px) {
            .x-page-notfound .x-notfound__buttons { flex-direction: column; }
            .x-page-notfound .x-notfound__buttons a:first-child { margin: 0 0 12px; }
          }
          @-webkit-keyframes pageload {
            0% { -webkit-transform: translateY(100px); transform: translateY(100px); opacity: 0; }
            to { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
          }
          @keyframes pageload {
            0% { -webkit-transform: translateY(100px); transform: translateY(100px); opacity: 0; }
            to { -webkit-transform: translateY(0); transform: translateY(0); opacity: 1; }
          }
        `}</style>
      </head>

      <body>
        <div className="x-page-notfound">
          <main className="x">
            <div className="x-download__note-header">
              <div className="x-download__note-header-content">
                <a href="/">
                  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="12" viewBox="0 0 7 12">
                    <path
                      fill="none"
                      fillRule="evenodd"
                      stroke="#FFF"
                      strokeWidth="1.5"
                      d="M6 11L1 6l5-5"
                      opacity=".8"
                    />
                  </svg>
                  Back to the main website
                </a>
                <div className="logo-container">
                  <svg
                    width="156"
                    height="32"
                    viewBox="0 0 156 32"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <title>Exodus Logo</title>
                    <path
                      d="M31.808 8.93488L18.1809 0V4.99554L26.9228 10.6762L25.8943 13.9304H18.1809V18.0696H25.8943L26.9228 21.3238L18.1809 27.0045V32L31.808 23.0937L29.5796 16.0143L31.808 8.93488Z"
                      fill="url(#paint0_linear_536_8492)"
                    />
                    <path
                      d="M6.32502 18.0696H14.0099V13.9304H6.29645L5.29656 10.6762L14.0099 4.99554V0L0.382812 8.93488L2.61114 16.0143L0.382812 23.0937L14.0385 32V27.0045L5.29656 21.3238L6.32502 18.0696Z"
                      fill="url(#paint1_linear_536_8492)"
                    />
                    <mask
                      id="mask0_536_8492"
                      style={{ 'mask-type': 'alpha' }}
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="32"
                      height="32"
                    >
                      <path
                        d="M31.808 8.93488L18.1809 0V4.99554L26.9228 10.6762L25.8943 13.9304H18.1809V18.0696H25.8943L26.9228 21.3238L18.1809 27.0045V32L31.808 23.0937L29.5796 16.0143L31.808 8.93488Z"
                        fill="url(#paint2_linear_536_8492)"
                      />
                      <path
                        d="M6.32502 18.0696H14.0099V13.9304H6.29645L5.29656 10.6762L14.0099 4.99554V0L0.382812 8.93488L2.61114 16.0143L0.382812 23.0937L14.0385 32V27.0045L5.29656 21.3238L6.32502 18.0696Z"
                        fill="url(#paint3_linear_536_8492)"
                      />
                    </mask>
                    <g mask="url(#mask0_536_8492)">
                      <rect
                        x="0.400391"
                        width="31.2"
                        height="32"
                        fill="url(#paint4_linear_536_8492)"
                      />
                    </g>
                    <path
                      d="M58.9207 8.7998V11.5974H46.0281V14.46H56.9826V17.2576H46.0281V20.4022H58.9207V23.1998H42.7207V8.7998H58.9207Z"
                      fill="white"
                    />
                    <path
                      d="M60.75 23.1998L68.1572 15.9131L60.9598 8.7998H65.5133L70.5913 13.9396L75.4595 8.7998H79.7192L72.5428 15.9131L79.95 23.1998H75.3546L70.5913 17.9082L65.1566 23.1998H60.75Z"
                      fill="white"
                    />
                    <path
                      d="M88.8319 8.7998C94.8332 8.7998 98.4422 11.805 98.4422 15.9998C98.4422 20.1946 94.8332 23.1998 88.8319 23.1998C82.8306 23.1998 79.2422 20.1946 79.2422 15.9998C79.2422 11.805 82.8306 8.7998 88.8319 8.7998ZM88.8319 11.492C85.2229 11.492 82.5625 13.3076 82.5625 15.9998C82.5625 18.692 85.2229 20.5076 88.8319 20.5076C92.4615 20.5076 95.1219 18.692 95.1219 15.9998C95.1219 13.3076 92.4615 11.492 88.8319 11.492Z"
                      fill="white"
                    />
                    <path
                      d="M110.404 8.7998C115.211 8.7998 118.107 11.4673 118.107 15.9781C118.107 20.5323 115.232 23.1998 110.446 23.1998H100.707V8.7998H110.404ZM114.707 15.9781C114.707 13.1588 113.028 11.5974 109.984 11.5974H104.002V20.4022H109.984C113.028 20.4022 114.707 18.8191 114.707 15.9781Z"
                      fill="white"
                    />
                    <path
                      d="M129.005 23.1998C123.944 23.1998 120.105 20.9026 120.105 16.6698V8.7998H123.417V16.3508C123.417 18.967 126.137 20.1794 129.005 20.1794C131.895 20.1794 134.615 18.9883 134.615 16.3508V8.7998H137.905V16.6698C137.905 20.9026 134.088 23.1998 129.005 23.1998Z"
                      fill="white"
                    />
                    <path
                      d="M147.174 23.1998C144.455 23.1998 141.523 22.6989 139.441 21.7598L140.525 19.0885C142.394 19.9442 144.965 20.4868 147.28 20.4868C149.936 20.4868 152.421 19.9233 152.421 18.9215C152.421 18.1911 151.635 17.8781 149.999 17.6276L145.368 17.1268C141.799 16.6468 140.057 15.4155 140.057 13.245C140.057 10.4485 143.01 8.7998 147.344 8.7998C149.957 8.7998 153.547 9.2798 155.395 10.1146L154.312 12.6607C152.485 11.9094 149.383 11.5337 147.195 11.5337C144.943 11.5337 143.456 12.0555 143.456 13.0155C143.456 13.6833 144.2 14.0172 146.112 14.2885L150.615 14.7894C154.057 15.2694 155.841 16.4381 155.841 18.7128C155.841 21.5928 152.06 23.1998 147.174 23.1998Z"
                      fill="white"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_536_8492"
                        x1="27.3996"
                        y1="34.2"
                        x2="18.2716"
                        y2="-3.51423"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0B46F9" />
                        <stop offset="1" stopColor="#BBFBE0" />
                      </linearGradient>
                      <linearGradient
                        id="paint1_linear_536_8492"
                        x1="27.3996"
                        y1="34.2"
                        x2="18.2716"
                        y2="-3.51423"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0B46F9" />
                        <stop offset="1" stopColor="#BBFBE0" />
                      </linearGradient>
                      <linearGradient
                        id="paint2_linear_536_8492"
                        x1="27.3996"
                        y1="34.2"
                        x2="18.2716"
                        y2="-3.51423"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0B46F9" />
                        <stop offset="1" stopColor="#BBFBE0" />
                      </linearGradient>
                      <linearGradient
                        id="paint3_linear_536_8492"
                        x1="27.3996"
                        y1="34.2"
                        x2="18.2716"
                        y2="-3.51423"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="#0B46F9" />
                        <stop offset="1" stopColor="#BBFBE0" />
                      </linearGradient>
                      <linearGradient
                        id="paint4_linear_536_8492"
                        x1="2.40039"
                        y1="7.2"
                        x2="18.2004"
                        y2="19"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop offset="0.119792" stopColor="#8952FF" stopOpacity="0.87" />
                        <stop offset="1" stopColor="#DABDFF" stopOpacity="0" />
                      </linearGradient>
                    </defs>
                  </svg>
                </div>
              </div>
            </div>

            <div className="x-notfound x__width">
              <span className="x-notfound__subheading">404</span>
              <h1 className="x-notfound__heading">Page Not Found</h1>

              <p className="x-notfound__txt">
                Looks like you've followed a broken link or entered a URL that doesn't exist on this
                site.
              </p>

              <div className="x-notfound__buttons">
                <a className="x__button x__button--secondary" href="https://support.exodus.com/">
                  <span className="x__button-align">
                    <span className="x__button-align-text">Visit our Support page</span>
                  </span>
                </a>

                <a href="/" className="x__button">
                  <span className="x__button-align">
                    <span className="x__button-align-text">Go to Exodus home page</span>
                  </span>
                </a>
              </div>
            </div>
          </main>
        </div>
      </body>
    </html>
  )
}
